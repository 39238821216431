import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Idea } from "../types/Idea";
import { createIdea, fetchIdeas } from "../api/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { Colors } from "../helpers/Colors";
import Loader from "../components/Loader";

const IdeaList = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [rows, setRows] = useState<Idea[]>([]);
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchIdeas(user!!.uid)
      .then((ideas) => {
        setRows(ideas);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const columns: GridColDef[] = [
    { field: "title", headerName: "Tittel", width: 640 },
  ];

  const handleClose = (
    event: React.FormEvent<HTMLInputElement>,
    reason: string
  ) => {
    setOpen(false);
  };

  const handleSetTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

  const onClickCreateIdea = () => {
    createIdea(title, user!!.uid).then((response) => {
      navigate("/ideas/" + response.id);
    });
  };

  if (isLoading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Loader />
      </Grid>
    );
  }

  return (
    <div className="flex-col border-t-1 border-color p-4 dark:text-white">
      <div style={{ height: "82vh", width: "100%", color: Colors.white }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[
            {
              value: 15,
              label: "",
            },
          ]}
          onRowClick={(event, value) => navigate("/ideas/" + event.id)}
          sx={{
            borderColor: Colors.white,
            color: Colors.white,
            ".MuiTablePagination-displayedRows": {
              color: Colors.white,
            },
            ".MuiIconButton-root": {
              color: Colors.white,
            },
          }}
        />
      </div>

      <Button
        variant="outlined"
        style={{ marginTop: 36, textTransform: "none" }}
        onClick={() => setOpen(true)}
      >
        Legg til
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            backgroundColor: Colors.backgroundPrimary,
            color: Colors.white,
            borderLeft: "1px solid #fefefe",
            borderTop: "1px solid #fefefe",
            borderRight: "1px solid #fefefe",
          }}
        >
          Opprett ny ide
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: Colors.backgroundPrimary,
            borderLeft: "1px solid #fefefe",
            borderRight: "1px solid #fefefe",
          }}
        >
          <Box className="p-2 w-80">
            <FormControl fullWidth>
              <TextField
                margin="dense"
                id="title"
                label="Tittel"
                type="text"
                fullWidth
                onChange={handleSetTitle}
                value={title}
                variant="standard"
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            paddingBottom: 3,
            paddingRight: 3,
            backgroundColor: Colors.backgroundPrimary,
            borderLeft: "1px solid #fefefe",
            borderBottom: "1px solid #fefefe",
            borderRight: "1px solid #fefefe",
          }}
        >
          <Button variant="outlined" onClick={onClickCreateIdea}>
            Opprett
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IdeaList;
