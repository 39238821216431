import React from 'react'
import { Box, CircularProgress } from '@mui/material';
import { Colors } from '../helpers/Colors';

const Loader = () => {
  return (
    <Box>
      <CircularProgress size={56}  style={{color: Colors.white, marginBottom: 18}} />
      <div style={{color: Colors.white, fontSize: 18}}>Laster</div>
    </Box>
  )
}

export default Loader