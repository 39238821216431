import { firestore } from "../firebase"
import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "@firebase/firestore"
import { Idea } from "../types/Idea"

const ideasCollectionName = "Ideas";
const ideasCollectionRef = collection(firestore, ideasCollectionName);

export const fetchIdeas: (userId: string) => Promise<Idea[]> = async (userId: string) => {
  const q = query(ideasCollectionRef, where("creatorUid", "==", userId));
  const querySnapshot = await getDocs(q);

  const ideas: Idea[] = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    ideas.push({
      id: doc.id,
      title: data.title,
      pitch: data.pitch,
      note: data.note,
    });
  });

  return ideas;
};

export const fetchIdea: (id: string) => Promise<Idea | null> = async (id: string) => {
  const docSnap = await getDoc(doc(firestore, ideasCollectionName, id));

  if (docSnap.exists()) {
    const data = docSnap.data();

    return {
      id: docSnap.id,
      title: data.title,
      pitch: data.pitch,
      note: data.note,
    }
  } else {
    return null;
  }
};

export const createIdea = async (title: string, userId: string) => {
  return await addDoc(ideasCollectionRef, {
    title: title,
    creatorUid: userId,
  });
};

export const saveIdea = async (idea: Idea) => {
  const ideaRef = doc(firestore, ideasCollectionName, idea.id);

  await updateDoc(ideaRef, {
    title: idea.title,
    pitch: idea.pitch,
    note: idea.note,
  })
};
