import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { Idea } from "../types/Idea";
import { fetchIdea, saveIdea } from "../api/firestore";
import { Colors } from "../helpers/Colors";
import Loader from "../components/Loader";

const IdeaDetails = () => {
  let params = useParams();
  const ideaId: string = params.ideaId ? params.ideaId : "";
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openAlertUpdateFailed, setOpenAlertUpdateFailed] = useState(false);
  const [title, setTitle] = useState("");
  const [pitch, setPitch] = useState("");
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchIdea(ideaId)
      .then((idea) => {
        setTitle(idea ? idea.title : "");
        setPitch(idea ? idea.pitch : "");
        setNote(idea ? idea.note : "");
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

  const handleSetPitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPitch(event.currentTarget.value);
  };

  const handleSetNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.currentTarget.value);
  };

  const handleSave = () => {
    setOpenBackdrop(true);
    setOpenAlertUpdateFailed(false);
    const updatedIdea: Idea = {
      id: ideaId,
      title: title,
      pitch: pitch,
      note: note,
    };
    saveIdea(updatedIdea)
      .then(() => {
        setOpenBackdrop(false);
      })
      .catch((error) => {
        setOpenBackdrop(false);
        setOpenAlertUpdateFailed(true);
      });
  };

  if (isLoading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Loader />
      </Grid>
    );
  }

  return (
    <div className="flex-col border-t-1 border-color p-4 dark:text-white">
      <div>
        <Box className="flex-col">
          {openAlertUpdateFailed && (
            <Box>
              <Alert
                variant="filled"
                severity="error"
                style={{ marginBottom: 12 }}
              >
                Kunne ikke oppdatere.
              </Alert>
            </Box>
          )}
          <Box>
            <TextField
              margin="dense"
              id="title"
              label="Tittel"
              type="text"
              fullWidth
              onChange={handleSetTitle}
              value={title}
              variant="standard"
            />
          </Box>
          <Box className="mt-4">
            <TextField
              margin="dense"
              id="pitch"
              label="Pitch"
              type="text"
              fullWidth
              onChange={handleSetPitch}
              value={pitch}
              variant="standard"
            />
          </Box>
          <Box className="mt-4">
            <TextField
              margin="dense"
              id="note"
              label="Note"
              multiline
              type="text"
              fullWidth
              onChange={handleSetNote}
              value={note}
              variant="standard"
            />
          </Box>
        </Box>
      </div>

      <Button
        style={{ marginTop: 36, textTransform: "none" }}
        variant="outlined"
        onClick={handleSave}
      >
        Lagre
      </Button>

      <Backdrop
        sx={{
          color: Colors.white,
          zIndex: (theme: { zIndex: { drawer: number } }) =>
            theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default IdeaDetails;
