import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, Grid, ThemeProvider } from '@mui/material';

import './App.css';

import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import IdeaList from './pages/IdeaList';
import Login from './pages/Login';
import IdeaDetails from './pages/IdeaDetails';
import { Colors } from './helpers/Colors';
import Loader from './components/Loader';

const App = () => {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return (
      <React.Fragment>
        <CssBaseline />
        <div style={styles.container}>
          <ThemeProvider theme={theme}>
            <Container maxWidth="md">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{height: '100vh'}}
              >
                <Loader />
              </Grid>
            </Container>
          </ThemeProvider>
        </div>
      </React.Fragment>
    )
  }
  
  return user ? (
    <React.Fragment>
      <CssBaseline />
      <div style={styles.container}>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            <BrowserRouter>
              <Routes>
                <Route path="/" element={(<IdeaList />)} />
                <Route path="/ideas/:ideaId" element={(<IdeaDetails />)} />
              </Routes>
            </BrowserRouter>
          </Container>
        </ThemeProvider>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <CssBaseline />
      <div style={styles.container}>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            <BrowserRouter>
              <Routes>
                <Route path="/" element={(<Login />)} />
              </Routes>
            </BrowserRouter>
          </Container>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}

const styles = {
  container: {
    backgroundColor: Colors.backgroundPrimary,
    height: '100vh',
  }
}

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: Colors.white,
          color: Colors.white,
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: Colors.white,
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: Colors.white,
          "&:before":{
            borderBottom:"1px solid #fefefe !important",}
        }
      },
    },
  },
});



export default App;
