import React, { useState } from 'react'

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const handleSetPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleLoginClick = () => {
    try {
      signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{height: '100vh'}}
    >
      <Box style={{border: '1px solid #fefefe'}}>
        <Box className='flex-col p-12 w-80'>
          <Box>
            <TextField
              margin="dense"
              id="email"
              label="E-post"
              type="email"
              fullWidth
              onChange={handleSetEmail}
              value={email}
              variant="standard"
            />
          </Box>

          <Box className='mt-2'>
            <TextField
              margin="dense"
              id="password"
              label="Passord"
              type="password"
              fullWidth
              onChange={handleSetPassword}
              value={password}
              variant="standard"
            />
          </Box>

          <Box className='mt-8'>
            <Button variant='outlined' onClick={handleLoginClick}>Logg inn</Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default Login